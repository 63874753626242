import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import axios from "axios";
import { Translate } from "../Translator";
import { Button } from "react-bootstrap";

import { ConfigurationApiCRMApplicationsGet } from "../../ExternalApi/ConfigurationApi.js";

import Loading from "../Loading.jsx";
import IntegrationWizardStepApplicationItem from "./IntegrationWizardStepApplicationItem.jsx";
import IntegrationWizardStepHeader from "./IntegrationWizardStepHeader.jsx";

import SearchPng from '../../Assets/Search.png';
import AddCustomPng from '../../Assets/AddCustom.png';

const IntegrationWizardStepApplication = (props) => {

    const [mCRMApplications, SetCRMApplications] = useState(null);

    const [mFilter, SetFilter] = useState("");
    const [mCRMApplicationsFiltered, SetCRMApplicationsFiltered] = useState(null);

    const OnAddCustomButtonClicked = async () => {
        props.OnApplicationSelected("")
    }

    useEffect(() => {
        async function loadResources(aCancelTokenSource) {
            const CRMApplications = await ConfigurationApiCRMApplicationsGet(props.SecurityToken, aCancelTokenSource)
            SetCRMApplications(CRMApplications);
            SetCRMApplicationsFiltered(CRMApplications);
        }
        
        const cancelToken = axios.CancelToken;
        const source = cancelToken.source();

        loadResources(source);
        
        return () => {
            source.cancel("axios request cancelled");
        };
      }, []); 

    const UpdateFilter = (aFilter) => {
        SetFilter(aFilter)
        const items = [...mCRMApplications];
        SetCRMApplicationsFiltered(items.filter(crmapplication => crmapplication.Id.toLowerCase().includes(aFilter.toLowerCase()) || (aFilter === "")))
    }

    const RenderCRMApplicationsInRows = () => { 
        
        const MaxCols = Math.trunc((props.StepWidth + 2) / 232)

        const items = [{Id: "", PngUrl: ""}].concat(mCRMApplicationsFiltered);
        const rows = [];
      
        while (items.length) {
            rows.push(items.splice(0, MaxCols));
        }
      
        return rows.map((row,rindex) => (
          <tr key={"crmapplicationrow" + rindex}>{row.map((crmApplication,index) => <IntegrationWizardStepApplicationItem key={"crmapplication" + index} SecurityToken={props.SecurityToken} Name={crmApplication.Id} PngUrl={crmApplication.PngUrl} Index={index} OnItemSelected={props.OnApplicationSelected}/>)}</tr>
        ));
    }

    if (mCRMApplicationsFiltered) 
    {
        return (        
            <div>
                <IntegrationWizardStepHeader Header={Translate("Choose an application preset")}/>

                <table width="100%" cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <td className="wizardsearch" align="left">
                            <img src={SearchPng} style={{position: 'absolute', paddingLeft: '12px', paddingTop: '18px', paddingBottom: '-16px', height: '36px'}}/>
                            <input type="text" style={{paddingLeft: '34px'}} size="40" value={mFilter || ""} placeholder="Search keyword" className="wizardsearch" onChange={evt => UpdateFilter(evt.target.value)}/>                           
                        </td>
                    </tr>
                </tbody>     
                </table>
                
                <br/>
                <table cellPadding="0" cellSpacing="0">
                <tbody>
                    {RenderCRMApplicationsInRows()}
                </tbody>
                </table>
            </div>
        );
    }
    else
    {
        return <Loading />;
    }
}

IntegrationWizardStepApplication.propTypes = {
  SecurityToken: PropTypes.string,
  StepWidth: PropTypes.number,
  OnApplicationSelected: PropTypes.func
}

export default IntegrationWizardStepApplication