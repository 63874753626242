import { format } from "date-fns";
import { ConfigurationApiTriggerScript } from "../ExternalApi/ConfigurationApi.js";
import { Translate } from "./Translator";

function ReplaceCallFields(aValue, aCallFields)
{
    aValue = aValue.replace("#(Caller number)",aCallFields["Caller number"])
    aValue = aValue.replace("#(Caller name)",aCallFields["Caller name"])
    aValue = aValue.replace("#(Device number)",aCallFields["Device number"])
    aValue = aValue.replace("#(Device name)",aCallFields["Device name"])
    aValue = aValue.replace("#(Ddi number)",aCallFields["Ddi number"])
    aValue = aValue.replace("#(Ddi name)",aCallFields["Ddi name"])
    aValue = aValue.replace("#(Start time)",format(aCallFields["Start time"], "yyyy-MM-dd HH:mm:ss"))
    aValue = aValue.replace("#(Application name)",aCallFields["Application name"])
    return aValue  
}

function ReplaceRecognitionFields(aValue, aRecognitionFields)
{    
    if (aRecognitionFields)
    {
        {aRecognitionFields.map((field) => 
            aValue = aValue.replace("$(" + field.FieldLabel + ")",field.DefaultTestValue)
            )}  
    }
    return aValue 
}

function ExecuteOpenWebPage(aScript, aCallFields, aRecognitionFields)
{
    let WebPage = aScript.WebPage
    WebPage = ReplaceCallFields(WebPage, aCallFields)
    WebPage = ReplaceRecognitionFields(WebPage, aRecognitionFields)
    window.open(WebPage)
    return ""
}

async function ExecuteServerCSD(aSecurityToken, aScript, aCallFields, aRecognitionFields)
{
    let CSDParams = aScript.CSDParams
    Object.keys(CSDParams).map(key => {
        CSDParams[key] = ReplaceCallFields(CSDParams[key], aCallFields)
        CSDParams[key] = ReplaceRecognitionFields(CSDParams[key], aRecognitionFields)
        return null
    })

    const ServerCSDError = await ConfigurationApiTriggerScript(aSecurityToken, aScript.CSDFileName, CSDParams, aCallFields, aRecognitionFields)
    return ServerCSDError
}

export function ExecuteScript(aSecurityToken, aScript, aCallFields, aRecognitionFields)
{
    try
    {
        return aScript.ScriptType === "ServerCSD" ? ExecuteServerCSD(aSecurityToken, aScript, aCallFields, aRecognitionFields) :
            (aScript.ScriptType === "OpenWebPage" ? ExecuteOpenWebPage(aScript, aCallFields, aRecognitionFields) : 
            null)
    }
    catch (ex)
    {
        var error = ex;
        try { error = ex.response.data.Exception; } catch {}  
        console.log(error);
    }
}