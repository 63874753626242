import React, {useState} from "react";
import PropTypes from 'prop-types';

const IntegrationWizardStepScriptItemCSD = (props) => {

    const [mCSDParams, SetCSDParams] = useState(props.Script.CSDParams); //used only as a repainter

    const UpdateCSDParameter = (identifier,newValue) =>
    {
        props.Script.CSDParams[identifier] = newValue
        SetCSDParams(newValue)
        props.OnScriptChange(null)
    }

    return (
        <span>
            {Object.keys(props.Script.CSDParams).map((key,index) => 
                <span key={index}>
                    <div className="wizardfieldname">{key.toUpperCase()} <span className="redtext">*</span></div>
                    <div className="wizardfieldvalue"><input autoComplete="new-password" placeholder="" type="text" value={props.Script.CSDParams[key]} onChange={evt => UpdateCSDParameter(key, evt.target.value)} className="wizardinputfield"/></div> 
                </span>
            )}   
        </span>
    );
}

IntegrationWizardStepScriptItemCSD.propTypes = {
  Script: PropTypes.object,
  PredefinedScript: PropTypes.object,
  OnScriptChange: PropTypes.func
}

export default IntegrationWizardStepScriptItemCSD