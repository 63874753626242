import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";

const DatasourcePerfectViewOnline = (props) => {
    
    const [mRepainter, SetRepainter] = useState(false)

    const [mFileName, SetFileName] = useState("")

    useEffect(() => {
        props.IntegrationSettings.DatasourceSettings.DatasourceVersion = "2"
        SetReady()
      }, []); 

    const GetStringBetween = (aBaseString,aStart,aEnd) =>
    {
        const StartIndex = aBaseString.indexOf(aStart)
        const EndIndex = aBaseString.indexOf(aEnd)
        if ((StartIndex > -1) && (EndIndex > -1) && (EndIndex > StartIndex))
        {
            return aBaseString.slice(StartIndex + aStart.length, EndIndex)
        }

        return ""
    }
      
    const FileChanged = (aEvent) =>
    {
        SetRepainter(!mRepainter)

        SetFileName(aEvent.target.value)
        
        const reader = new FileReader()
        reader.onload = function(event) {
          const XmlContent = event.target.result          
          const CredentialsXmlContent = GetStringBetween(XmlContent,"<Credentials>","</Credentials>")
          props.IntegrationSettings.DatasourceSettings.apikey = GetStringBetween(CredentialsXmlContent,"<ApiKey>","</ApiKey>")
          props.IntegrationSettings.DatasourceSettings.databaseid = GetStringBetween(CredentialsXmlContent,"<DatabaseId>","</DatabaseId>")
          props.IntegrationSettings.DatasourceSettings.userid = GetStringBetween(CredentialsXmlContent,"<UserId>","</UserId>")
          props.IntegrationSettings.DatasourceSettings.ApplicationUrl = GetStringBetween(CredentialsXmlContent,"<ApplicationUrl>","</ApplicationUrl>")
          props.IntegrationSettings.DatasourceSettings.ApiUrl = GetStringBetween(CredentialsXmlContent,"<ApiUrl>","</ApiUrl>")
          
          SetReady()
        };      
        reader.readAsText(aEvent.target.files[0]) //needed to trigger the onload
    }

    const SetReady = () => 
    {
        if (props.IntegrationSettings.DatasourceSettings.apikey
            && props.IntegrationSettings.DatasourceSettings.databaseid
            && props.IntegrationSettings.DatasourceSettings.userid) 
        {
            props.OnSetReady(true)
        }
        else
        {
            props.OnSetReady(false)
        }
    }

    const BrandingId = sessionStorage.getItem("BrandingId")
    
    return (
        <div className="scriptitembody">
            <div className="wizardfieldname">{Translate("Filename").toUpperCase()} <span className="redtext">*</span></div><br/>
            <div className="wizardfieldvalue"><input type="file" className={BrandingId === "enreach" ? "filebrandbuttonprimary" : ""} value={mFileName || ""} onChange={evt => FileChanged(evt)}/></div>
        </div>
    );
}

DatasourcePerfectViewOnline.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourcePerfectViewOnline