import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import Button from 'react-bootstrap/Button';

import BackPng from '../../Assets/Back.png';
import NextPng from '../../Assets/Next.png';

const IntegrationWizardNavigation = (props) => {
    
    const BrandingId = sessionStorage.getItem("BrandingId")
    
    return ( 
        <table width="100%" cellPadding="10" cellSpacing="10">
        <tbody>
        <tr>               
            <td width="*">&nbsp;</td>
            {props.BackVisible ? <td width="150" align="left"><Button variant="outline-dark" className={BrandingId === "enreach" ? "brandbuttonprimary" : "wizardbackbutton"} style={{ width: '150px'}} block={true} onClick={props.OnBack} type="submit"><img src={BackPng} className="wizardbuttonicon" /> &nbsp; {Translate("BACK")}</Button></td> : null}                 
            {props.NextVisible ? <td width="150" align="left"><Button variant="dark" className={BrandingId === "enreach" ? "brandbuttonprimary" : "wizardnextbutton"} style={{ width: '150px'}} onClick={props.OnNext} type="submit" disabled={!props.NextEnabled}>{Translate(props.NextCaption)} &nbsp; <img src={NextPng} className="wizardbuttonicon" /></Button></td> : null}
        </tr>
        </tbody>
        </table>
    ); 
};

IntegrationWizardNavigation.propTypes = {
    BackVisible: PropTypes.bool,
    NextVisible: PropTypes.bool,
    NextEnabled: PropTypes.bool,
    NextCaption: PropTypes.string,
    OnBack: PropTypes.func,
    OnNext: PropTypes.func
  }

export default IntegrationWizardNavigation;