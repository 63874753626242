import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import Button from 'react-bootstrap/Button';

import EnreachCustomApplicationPng from '../../Assets/EnreachCustomApplication.png';
import UnknownCRMPng from '../../Assets/UnknownCRM.png';

const IntegrationWizardStepApplicationItem = (props) => {
    
    const BrandingId = sessionStorage.getItem("BrandingId")
    const BrandingColor = sessionStorage.getItem("BrandingColor")

    return (
        props.Name ?
            <td align="center" style={{paddingRight: '20px', paddingBottom: '20px'}}>

                <div className="surface wizardapplicationshadow wizardapplicationitem">
                    <table cellPadding="6" cellSpacing="0" width="200" height="240" onClick={() => props.OnItemSelected(props.SecurityToken,props.Name)}>
                    <tbody>
                    <tr height="*">
                        <td className="wizardapplicationitemtop surfacedividerbottom" align="center">
                            <br/>
                            <img src={props.PngUrl} alt={props.Name}/>
                            <br clear="all"/>
                        </td>
                    </tr>
                    <tr height="10" align={BrandingId === "enreach" ? "center" : "left"}>
                        <td className="wizardapplicationitem">{props.Name}</td>
                    </tr>
                    <tr height="10">
                        <td align="left">
                            <Button style={BrandingId === "enreach" ? {width: '100%'} : {}} variant="dark" className={BrandingId === "enreach" ? "brandbuttonprimary" : "wizardapplicationitemadd"}>{Translate("Configure").toUpperCase() + " 🡢"}</Button>
                        </td>
                    </tr>
                    </tbody>
                    </table> 
                </div>
            </td> 
                
            : BrandingId === "enreach" ?

            <td align="center" style={{paddingRight: '20px', paddingBottom: '20px'}}>

                <div className="surface wizardapplicationshadow wizardapplicationitem">
                    <table cellPadding="6" cellSpacing="0" width="200" height="240" onClick={() => props.OnItemSelected(props.SecurityToken,props.Name)}>
                    <tbody>
                    <tr height="*">
                        <td className="wizardapplicationitemtop surfacedividerbottom" align="center">
                            <br/>
                            <img src={EnreachCustomApplicationPng} height="64px" alt={Translate("Didn't find your preset?")}/>
                        </td>
                    </tr>
                    <tr height="10">
                        <td className="wizardapplicationitem">{Translate("Didn't find your preset?")}</td>
                    </tr>
                    <tr height="10">
                        <td>
                            <Button style={{width: '100%'}} variant="dark" className="brandbuttonprimary">{"+ " + Translate("Add custom").toUpperCase()}</Button>
                        </td>
                    </tr>
                    </tbody>
                    </table> 
                </div>
            </td> 

            : 

            <td align="center" style={{paddingRight: '20px', paddingBottom: '20px'}}>

                <div className="surface wizardapplicationshadow wizardapplicationitem wizardapplicationitemcustom" style={BrandingId === "enreach" ? {background: BrandingColor, borderColor: BrandingColor} : {}}>
                    <table width="200" height="240" cellPadding="6" cellSpacing="0" onClick={() => props.OnItemSelected(props.SecurityToken,props.Name)}>
                    <tbody>
                    <tr>
                        <td align="center">
                            <img src={UnknownCRMPng}/>
                        </td>
                    </tr>
                    <tr>
                        <td align="left" valign="top">
                            {Translate("Didn't find your preset?")}
                        </td>
                    </tr>
                    <tr>
                        <td className="wizardapplicationitem" valign="bottom" align="center">
                            <Button variant="dark" className="wizardapplicationitemadd">{Translate("Add custom").toUpperCase() + " 🡢"}</Button>
                        </td>
                    </tr>
                    </tbody>
                    </table> 
                </div>

            </td>
    );
}

IntegrationWizardStepApplicationItem.propTypes = {
  SecurityToken: PropTypes.string,
  Name: PropTypes.string,
  PngUrl: PropTypes.string,
  Index: PropTypes.number,
  OnItemSelected: PropTypes.func
}

export default IntegrationWizardStepApplicationItem