import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import { Translate } from "../Translator"
import { ConfigurationApiIntegrationGetSyncState } from "../../ExternalApi/ConfigurationApi"

const MainIntegrationSyncInfo = (props) => {
  
    let mStatusValue = null
    const [mStatus, SetStatus] = useState(null) 

    useEffect(() => {

        ExecuteInterval() //do not wait 5 seconds for the first interval
        const Interval = setInterval(() => ExecuteInterval(), 5000); 

        return () => clearInterval(Interval);
    }, []); 

    const ExecuteInterval = () =>
    {
        ConfigurationApiIntegrationGetSyncState(props.SecurityToken, props.IntegrationGuid).then((status) => NewStatus(status))
    }

    const NewStatus = (aStatus) =>
    {
        console.log("NewStatus: " + JSON.stringify(aStatus))
        if (mStatusValue === null)
        {
            mStatusValue = aStatus
            SetStatus(mStatusValue)
        }
        else if ((aStatus.Busy === mStatusValue.Busy) && (aStatus.UpdateCounter === mStatusValue.UpdateCounter) && (aStatus.StatusMessage === mStatusValue.StatusMessage) && (aStatus.Progress === mStatusValue.Progress))
        {
            //nothing changed
        }
        else 
        {
            if (aStatus.UpdateCounter !== mStatusValue.UpdateCounter)
            {
                props.OnIntegrationsRefresh()
            } 
            mStatusValue = aStatus
            SetStatus(mStatusValue)
        } 
    }

    const NextUpdateString = (nextUpdateTimeString) => 
    {
        if (nextUpdateTimeString)
        {
            let line = Translate("Automatic synchronization in") + " "

            const msUntil = Date.parse(nextUpdateTimeString + "+0:00") //This is a utc time. Force it to be recognized as such
            const msNow = new Date() //Means now

            let diff = Math.ceil((msUntil - msNow) / 60000)
            if (diff < 2)
            {
                return line + "1 " + Translate("minute")
            }
            else if (diff < 120)
            {
                return line + diff + " " + Translate("minutes")
            }
            else
            {
                diff = Math.ceil(diff / 60)
                if (diff < 48)
                {
                    return line + diff + " " + Translate("hours")
                }
                else
                {
                    diff = Math.ceil(diff / 24)
                    return line + diff + " " + Translate("days")
                }
            } 
        }
        else if (!props.AutoSync)
        {
            return Translate("Automatic synchronization disabled")
        }
        else
        {
            return Translate("Next synchronization could not be determined")
        } 
    }

    let SyncText = null
    if (mStatus && mStatus.Busy)
    {
        SyncText = (mStatus.StatusMessage ? mStatus.StatusMessage + " " : "") + (mStatus.Progress ? mStatus.Progress : "")
    }

    return (
        <span>
            {(SyncText !== null) ? <div>{SyncText}</div> :
                (!props.LastSynced ? <div>{Translate("First synchronization is being scheduled")}</div> : 
                    <div>{NextUpdateString(props.NextSync)}</div>)}

            {(SyncText === null) ? <div className="errortext">{props.LastError}</div> : <div/>}                    
        </span>
    );
}

MainIntegrationSyncInfo.propTypes = {
  SecurityToken: PropTypes.string,
  IntegrationGuid: PropTypes.string,
  AutoSync: PropTypes.bool,
  NextSync: PropTypes.string,
  LastSynced: PropTypes.string,
  LastError: PropTypes.string,
  OnIntegrationsRefresh: PropTypes.func
}

export default MainIntegrationSyncInfo