import React, {useState} from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Popup from 'reactjs-popup';
import { Translate } from "../Translator";

const IntegrationWizardStepScriptItemWebPage = (props) => {

    const [mWebPage, SetWebPage] = useState(props.Script.WebPage); //used only as a repainter

    const UpdateWebPage = (newValue) =>
    {
        props.Script.WebPage = newValue
        SetWebPage(newValue)
        props.OnScriptChange(null)
    }

    const BrandingId = sessionStorage.getItem("BrandingId")

    return (
        <span>
            <div className="wizardfieldname">{Translate("Webpage")} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.Script.WebPage} onChange={evt => UpdateWebPage(evt.target.value)} className="wizardinputfield"/></div> 

            <Popup contentStyle={{ borderRadius: '16px', padding: '8px', background: 'var(--brand_wizardpopup_background)', color: 'var(--brand_wizardpopup_color)', borderWidth: '0px', boxShadow: '0px 6px 7px rgba(0, 0, 0, .05)' }} trigger={<Button variant="primary" className={BrandingId === "enreach" ? "brandbuttonprimary" : "brandbuttonkeeporiginalstyle"} id="recognitionpopup">+ Add variable</Button>} arrow={false} position="bottom left" closeOnDocumentClick={true}>
                {props.DatasourceFields && props.DatasourceFields.length > 0 ? <div className="wizardpopupheader">{Translate("Recognition fields")}</div> : <span/>}
                {props.DatasourceFields && props.DatasourceFields.length > 0 ? props.DatasourceFields.map((field,findex) => 
                    <div key={findex} onClick={() => { UpdateWebPage(props.Script.WebPage + "$(" + field.FieldLabel + ")"); document.getElementById("recognitionpopup").click()}} className="wizardpopupitem">{"$(" + field.FieldLabel + ")"}</div>) 
                    : <span/>
                }
                {props.DatasourceFields && props.DatasourceFields.length > 0 ? <br/> : <span/>}
                <div className="wizardpopupheader">{Translate("Call fields")}</div>
                <div onClick={() => { UpdateWebPage(props.Script.WebPage + "#(Caller number)"); document.getElementById("recognitionpopup").click()}} className="wizardpopupitem">{"#(Caller number)"}</div>
                <div onClick={() => { UpdateWebPage(props.Script.WebPage + "#(Caller name)"); document.getElementById("recognitionpopup").click()}} className="wizardpopupitem">{"#(Caller name)"}</div>
                <div onClick={() => { UpdateWebPage(props.Script.WebPage + "#(Device number)"); document.getElementById("recognitionpopup").click()}} className="wizardpopupitem">{"#(Device number)"}</div>
                <div onClick={() => { UpdateWebPage(props.Script.WebPage + "#(Device name)"); document.getElementById("recognitionpopup").click()}} className="wizardpopupitem">{"#(Device name)"}</div>
                <div onClick={() => { UpdateWebPage(props.Script.WebPage + "#(Ddi number)"); document.getElementById("recognitionpopup").click()}} className="wizardpopupitem">{"#(Ddi number)"}</div>
                <div onClick={() => { UpdateWebPage(props.Script.WebPage + "#(Ddi name)"); document.getElementById("recognitionpopup").click()}} className="wizardpopupitem">{"#(Ddi name)"}</div>
            </Popup>
            
            <br/>
        </span>
    );
}

IntegrationWizardStepScriptItemWebPage.propTypes = {
  DatasourceFields: PropTypes.array,
  Script: PropTypes.object,
  PredefinedScript: PropTypes.object,
  OnScriptChange: PropTypes.func
}

export default IntegrationWizardStepScriptItemWebPage