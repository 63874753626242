import React from "react";
import PropTypes from 'prop-types';

import { GetLanguageId, SetLanguageId, Translate  } from "../Translator";

import IntegrationWizardStepHeader from "../IntegrationWizard/IntegrationWizardStepHeader.jsx";

import LanguagePng from '../../Assets/Language.png';

const MainHome = (props) => {

  const UpdateLanguage = (aNewLanguageId) => {
    SetLanguageId(aNewLanguageId)
    props.OnLanguageChanged()
  }

  const BrandingProductName = sessionStorage.getItem("BrandingProductName")

  return (
    <span>
      <IntegrationWizardStepHeader Header={Translate("Welcome to the <branding_product> Webconfigurator for CRM integration").replace("<branding_product>", BrandingProductName)} SubHeader={Translate("With this tool you can manage your CRM integrations")}/>

      <div className="surface namevalue">
        <table width="100%" cellPadding={16}>
        <tbody>
        <tr>
          <td width="200" className="namevaluenamecolumn surfacedividerbottom" valign="center">{Translate("Your organization")}</td>
          <td width="*" className="surfacedividerbottom" valign="center">{props.TenantName}</td>
        </tr>
        <tr>
          <td width="200" className="namevaluenamecolumn surfacedividerbottom" valign="center">{Translate("Signed in as")}</td>
          <td width="*" className="surfacedividerbottom" valign="center">{props.UserName}</td>
        </tr>
        <tr>
          <td width="200" className="namevaluenamecolumn" valign="center">{Translate("Language")}</td>
          <td width="*" valign="center">
            <img src={LanguagePng} style={{position: 'absolute', paddingLeft: '7px', paddingTop: '10px'}}/> &nbsp;
            <select name="Language" style={{marginLeft: '-8px', paddingLeft: '34px'}} onChange={evt => UpdateLanguage(evt.target.value)} value={GetLanguageId() === "nederlands" ? "nederlands" : ""}>
              <option value="">English</option>
              <option value="nederlands">Nederlands</option>
            </select>        
          </td>
        </tr>
        </tbody>
        </table>
      </div>
    </span>  
  )
}

MainHome.propTypes = {
  TenantName: PropTypes.string,
  UserName: PropTypes.string,
  BrandingProductName: PropTypes.string,
  OnLanguageChanged: PropTypes.func,
  OnSignOut: PropTypes.func
}

export default MainHome