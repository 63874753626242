import React from "react";
import Main from "./Components/Main";
import CRM from "./Components/CRM";
import IntegrationWizardWrapper from "./Components/IntegrationWizardWrapper";
import ScriptWizardWrapper from "./Components/ScriptWizardWrapper";
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import "./css/checkbox.css"
import "./css/radiobutton.css"
import "./css/branding.css"

function App() {  
  
  let branding_id = "cloudcti"
  let branding_signinmethod = ""
  let branding_productname = "CloudCTI"
  let branding_color = '#0F9DDD'
  if ((window.location.hostname === "contactconnect.enreach.cloudcti.nl") //release
      || (window.location.hostname === "webconfiguratortest.cloudcti.nl")) //beta
  {
    branding_id = "enreach"
    branding_signinmethod = "voiceworks"
    branding_productname = "Enreach Connect"
    branding_color = '#8A54B8'
  }

  function AppyBrand() 
  {
    //CLOUDCTI-DEFINED COLOR TABLE
    const cloudcti_color_background = '#FFFFFF' 
    const cloudcti_color_border = '#71C1EC' 
    const cloudcti_color_fontlightbg = '#000000'
    const cloudcti_color_fontdarkbg = '#FFFFFF'  
    const cloudcti_color_brand = '#0F9DDD' 
    const cloudcti_color_brandlight = '#D5E6FB' 
    const cloudcti_color_popupbg = ' #0069D9' 
    const cloudcti_color_popuphoverbg = ' #0F9DDD' 
    const cloudcti_color_fontlightbgdisabled = '#222222' 
    const cloudcti_color_fontdarkbgdisabled = '#DDDDDD' 

    //CLOUDCTI-DEFINED FONT TABLE
    const cloudcti_font_regular = '14px "Roboto",sans-serif'
    const cloudcti_font_large = '16px "Roboto",sans-serif'
    const cloudcti_font_small = '12px "Roboto",sans-serif'
    const cloudcti_font_title = 'bold 24px "Roboto",sans-serif'
    const cloudcti_font_maintitle = 'bold 36px "Roboto",sans-serif'
    const cloudcti_font_wizardfieldname = 'bold 12px "Roboto",sans-serif'
    const cloudcti_font_wizardintegrationcard = 'bolder 16px sans-serif'

    let branding_style_font = cloudcti_font_regular
    let branding_style_color = cloudcti_color_fontlightbg
    let branding_style_background = cloudcti_color_background
    let branding_style_color_greyedout = 'gray'
    let branding_style_color_error = 'red'
    let branding_style_color_link = '#0066CC' //clHotlight
    let branding_style_color_linkhighlight = '#0078D7' //clHighlight
    let branding_style_checkbox_bordercolor = '#000000'
    let branding_style_checkbox_bordercolorhover = '#000000'
    let branding_style_checkbox_bordercolorchecked = '#000000'
    let branding_style_radio_primarycolor = '#000000'
    let branding_style_radio_hovercolor = '#000000'
    let branding_style_radio_checkedcolor = '#000000'
    let branding_style_button_font = cloudcti_font_regular    
    let branding_style_primarybutton_background = '' //not used - buttons use original style
    let branding_style_primarybutton_backgroundhighlight = '' //not used - buttons use original style
    let branding_style_primarybutton_color = '' //not used - buttons use original style
    let branding_style_outlinebutton_background = '' //not used - buttons use original style
    let branding_style_outlinebutton_backgroundhighlight = '' //not used - buttons use original style
    let branding_style_outlinebutton_color = '' //not used - buttons use original style
    let branding_style_outlinebutton_colorhighlight = '' //not used - buttons use original style
    let branding_style_signoutbutton_backgroundhover = cloudcti_color_background
    let branding_style_maintitle_background = cloudcti_color_brandlight
    let branding_style_maintitle_font = cloudcti_font_maintitle
    let branding_style_maintitle_color = cloudcti_color_fontlightbg    
    let branding_style_surface_background = cloudcti_color_background
    let branding_style_surface_color = cloudcti_color_fontlightbg
    let branding_style_surface_selected_background = cloudcti_color_background
    let branding_style_surface_selected_color = cloudcti_color_fontlightbg
    let branding_style_surface_disabled_color = cloudcti_color_fontdarkbgdisabled
    let branding_style_surface_border = '1px ' + cloudcti_color_border + ' solid'
    let branding_style_title_font = cloudcti_font_title
    let branding_style_subtitle_font = cloudcti_font_regular
    let branding_style_mainmenu_background = cloudcti_color_brand
    let branding_style_mainmenu_color = cloudcti_color_fontdarkbg
    let branding_style_mainmenu_font = cloudcti_font_regular
    let branding_style_separatorbar_height = '10px'
    let branding_style_separatorbar_width = '50px'
    let branding_style_separatorbar_background = cloudcti_color_brand
    let branding_style_namevalue_color = cloudcti_color_fontlightbg
    let branding_style_namevalue_font = cloudcti_font_large
    let branding_style_copyright_color = cloudcti_color_fontlightbgdisabled
    let branding_style_copyright_font = cloudcti_font_small
    let branding_style_wizardfieldname_font = cloudcti_font_wizardfieldname
    let branding_style_wizardfooter_background = cloudcti_color_brand
    let branding_style_wizardsurface_background = cloudcti_color_brandlight
    let branding_style_wizardpopup_background = cloudcti_color_popupbg
    let branding_style_wizardpopup_hover_background = cloudcti_color_popuphoverbg
    let branding_style_wizardpopup_color = cloudcti_color_fontdarkbg
    let branding_style_wizardpopup_font = cloudcti_font_regular
    let branding_style_wizardpopupheader_font = cloudcti_font_small
    let branding_style_wizardintegrationcard_font = cloudcti_font_wizardintegrationcard

    let branding_color_font = "#ffffff" //the font over a branding color
    let branding_color_light = "#d5e6fb" //the brandinglight color
    let branding_color_light_font = "#000000" //the font over a brandinglight color
    
    //if (window.location.href.startsWith("https://contactconnect.enreach.cloudcti.nl"))
    if ((window.location.hostname === "contactconnect.enreach.cloudcti.nl") //release
      || (window.location.hostname === "webconfiguratortest.cloudcti.nl")) //beta
    {
      //ENREACH-DEFINED COLOR TABLE
      const enreach_color_base = '#FCFCFC' //Navbar + cards and some backgrounds.
      const enreach_color_brand50 = '#F9F5FB' //Main background color
      const enreach_color_brand100 = '#E6D7EF' //Used for current active tab in the navbar
      const enreach_color_brand600 = '#8A54B8' //Color for buttons
      const enreach_color_neutral100 = '#EEEEEE' //Used as a background for the Availability and Open case queue top function and as the divider line for the list item (settings)
      const enreach_color_neutral200 = '#E7E5E5' //Used for borders or seperator lines and other text fields
      const enreach_color_neutral500 = '#ACA6A7' //Placeholder text
      const enreach_color_neutral900 = '#271A1D' //Used for black text and icons
      const enreach_color_successlight = '#C5F4D0' //Used in combination with Success, mostly as an accompanying background color
      const enreach_color_error = '#DD4B4B' //Used for error messages or important elements in the UI
      const enreach_color_errorlight = '#F4C5C5' //Alternative lighter version of the error color token

      //ENREACH-DEFINED FONT TABLE
      const enreach_font_navtitle = 'bold 34px "Roboto Condensed",sans-serif' //Used for nav bar title
      const enreach_font_title = 'bold 24px "Roboto Condensed",sans-serif' //Used for titles
      const enreach_font_bodybold = 'bold 16px "Open Sans",sans-serif' //Mostly menu items, button text or bold body text
      const enreach_font_body = '16px "Open Sans",sans-serif' //Used for normal pieces of text, options or something seperate from other elements
      const enreach_font_subbody = '14px "Open Sans",sans-serif' //Mostly used for subtitles and in this instance the label above the text field
      const enreach_font_microcopy = '12px "Open Sans",sans-serif' //Usually used for the smallest text/information

      //style
      branding_style_font = enreach_font_body
      branding_style_color = enreach_color_neutral900
      branding_style_background = enreach_color_brand50
      branding_style_color_greyedout = enreach_color_neutral500
      branding_style_color_error = enreach_color_error
      branding_style_color_link = enreach_color_neutral900
      branding_style_color_linkhighlight = enreach_color_brand600
      branding_style_checkbox_bordercolor = enreach_color_brand600
      branding_style_checkbox_bordercolorhover = enreach_color_brand600
      branding_style_checkbox_bordercolorchecked = enreach_color_brand600
      branding_style_radio_primarycolor = enreach_color_brand600
      branding_style_radio_hovercolor = enreach_color_brand600
      branding_style_radio_checkedcolor = enreach_color_brand600
      branding_style_button_font = enreach_font_bodybold   
      branding_style_primarybutton_background = enreach_color_brand600
      branding_style_primarybutton_backgroundhighlight = '#6001B5' //from enreach website     
      branding_style_primarybutton_color = '#FFFFFF' //specifically specified even though it is not in their color table
      branding_style_outlinebutton_background = enreach_color_base
      branding_style_outlinebutton_backgroundhighlight = '#7A17F8' //from enreach website     
      branding_style_outlinebutton_color = enreach_color_neutral900
      branding_style_outlinebutton_colorhighlight = '#FFFFFF'
      branding_style_signoutbutton_backgroundhover = enreach_color_brand50
      branding_style_maintitle_background = enreach_color_base
      branding_style_maintitle_font = enreach_font_navtitle
      branding_style_maintitle_color = enreach_color_neutral900
      branding_style_surface_background = enreach_color_base
      branding_style_surface_color = enreach_color_neutral900
      branding_style_surface_selected_background = enreach_color_brand100
      branding_style_surface_selected_color = enreach_color_neutral900
      branding_style_surface_disabled_color = enreach_color_neutral500
      branding_style_surface_border = '1px #E5E5E6 solid' //specifically specified even though it is not in their color table
      branding_style_title_font = enreach_font_title
      branding_style_subtitle_font = enreach_font_subbody
      branding_style_mainmenu_background = enreach_color_base
      branding_style_mainmenu_color = enreach_color_neutral900
      branding_style_mainmenu_font = enreach_font_bodybold
      branding_style_separatorbar_height = '2px'
      branding_style_separatorbar_width = '20px'
      branding_style_separatorbar_background = enreach_color_brand600
      branding_style_namevalue_color = enreach_color_neutral900
      branding_style_namevalue_font = enreach_font_body
      branding_style_copyright_color = enreach_color_neutral900
      branding_style_copyright_font = enreach_font_microcopy
      branding_style_wizardfieldname_font = enreach_font_subbody
      branding_style_wizardfooter_background = enreach_color_brand50
      branding_style_wizardsurface_background = enreach_color_base
      branding_style_wizardpopup_background = enreach_color_base
      branding_style_wizardpopup_hover_background = enreach_color_neutral100    
      branding_style_wizardpopup_color = enreach_color_neutral900
      branding_style_wizardpopup_font = enreach_font_body
      branding_style_wizardpopupheader_font = enreach_font_microcopy
      branding_style_wizardintegrationcard_font = enreach_font_bodybold

      branding_color_font = '' //not used - enreach uses its own navigation
      branding_color_light = '' //not used - enreach uses its own navigation
      branding_color_light_font = '' //not used - enreach uses its own navigation
    }

    var r = document.querySelector(':root');
    r.style.setProperty('--brand_font', branding_style_font)
    r.style.setProperty('--brand_color', branding_style_color)
    r.style.setProperty('--brand_background', branding_style_background)
    r.style.setProperty('--brand_color_greyedout', branding_style_color_greyedout)
    r.style.setProperty('--brand_color_error', branding_style_color_error)    
    r.style.setProperty('--brand_color_link', branding_style_color_link)    
    r.style.setProperty('--brand_color_linkhighlight', branding_style_color_linkhighlight) 
    r.style.setProperty('--brand_checkbox_bordercolor', branding_style_checkbox_bordercolor) 
    r.style.setProperty('--brand_checkbox_bordercolorhover', branding_style_checkbox_bordercolorhover) 
    r.style.setProperty('--brand_checkbox_bordercolorchecked', branding_style_checkbox_bordercolorchecked)
    r.style.setProperty('--brand_radio_primarycolor', branding_style_radio_primarycolor) 
    r.style.setProperty('--brand_radio_hovercolor', branding_style_radio_hovercolor) 
    r.style.setProperty('--brand_radio_checkedcolor', branding_style_radio_checkedcolor)
    r.style.setProperty('--brand_button_font', branding_style_button_font)          
    r.style.setProperty('--brand_primarybutton_background', branding_style_primarybutton_background)
    r.style.setProperty('--brand_primarybutton_backgroundhighlight', branding_style_primarybutton_backgroundhighlight)
    r.style.setProperty('--brand_primarybutton_color', branding_style_primarybutton_color)      
    r.style.setProperty('--brand_outlinebutton_background', branding_style_outlinebutton_background)
    r.style.setProperty('--brand_outlinebutton_backgroundhighlight', branding_style_outlinebutton_backgroundhighlight)
    r.style.setProperty('--brand_outlinebutton_color', branding_style_outlinebutton_color)  
    r.style.setProperty('--brand_outlinebutton_colorhighlight', branding_style_outlinebutton_colorhighlight) 
    r.style.setProperty('--brand_maintitle_background', branding_style_maintitle_background)
    r.style.setProperty('--brand_signoutbutton_backgroundhover', branding_style_signoutbutton_backgroundhover)
    r.style.setProperty('--brand_maintitle_font', branding_style_maintitle_font)
    r.style.setProperty('--brand_maintitle_color', branding_style_maintitle_color)
    r.style.setProperty('--brand_surface_background', branding_style_surface_background)
    r.style.setProperty('--brand_surface_color', branding_style_surface_color)
    r.style.setProperty('--brand_surface_selected_background', branding_style_surface_selected_background) 
    r.style.setProperty('--brand_surface_selected_color', branding_style_surface_selected_color)  
    r.style.setProperty('--brand_surface_disabled_color', branding_style_surface_disabled_color)
    r.style.setProperty('--brand_surface_border', branding_style_surface_border)
    r.style.setProperty('--brand_title_font', branding_style_title_font)
    r.style.setProperty('--brand_subtitle_font', branding_style_subtitle_font)
    r.style.setProperty('--brand_mainmenu_background', branding_style_mainmenu_background)
    r.style.setProperty('--brand_mainmenu_color', branding_style_mainmenu_color)
    r.style.setProperty('--brand_mainmenu_font', branding_style_mainmenu_font)
    r.style.setProperty('--brand_separatorbar_height', branding_style_separatorbar_height)
    r.style.setProperty('--brand_separatorbar_width', branding_style_separatorbar_width)
    r.style.setProperty('--brand_separatorbar_background', branding_style_separatorbar_background)
    r.style.setProperty('--brand_namevalue_color', branding_style_namevalue_color)
    r.style.setProperty('--brand_namevalue_font', branding_style_namevalue_font)    
    r.style.setProperty('--brand_copyright_font', branding_style_copyright_font)
    r.style.setProperty('--brand_copyright_color', branding_style_copyright_color)
    r.style.setProperty('--brand_wizardfieldname_font', branding_style_wizardfieldname_font)
    r.style.setProperty('--brand_wizardfooter_background', branding_style_wizardfooter_background)    
    r.style.setProperty('--brand_wizardsurface_background', branding_style_wizardsurface_background)  
    r.style.setProperty('--brand_wizardpopup_background', branding_style_wizardpopup_background)    
    r.style.setProperty('--brand_wizardpopup_hover_background', branding_style_wizardpopup_hover_background) 
    r.style.setProperty('--brand_wizardpopup_color', branding_style_wizardpopup_color)    
    r.style.setProperty('--brand_wizardpopup_font', branding_style_wizardpopup_font)    
    r.style.setProperty('--brand_wizardpopupheader_font', branding_style_wizardpopupheader_font)  
    r.style.setProperty('--brand_wizardintegrationcard_font', branding_style_wizardintegrationcard_font)     
    
    sessionStorage.setItem("BrandingId", branding_id)
    sessionStorage.setItem("BrandingSigninMethod", branding_signinmethod)
    sessionStorage.setItem("BrandingColor", branding_color)
    sessionStorage.setItem("BrandingProductName", branding_productname)

    //used only in IntegrationWizardHeader (cloudcti version)
    sessionStorage.setItem("BrandingColorFont", branding_color_font)
    sessionStorage.setItem("BrandingColorLight", branding_color_light)
    sessionStorage.setItem("BrandingColorLightFont", branding_color_light_font)
  }

  function AuthRoute({ children, ...rest }) {
    let securityToken = null
    try
    {
      //debug
      //return (<Route {...rest} render={({ location }) => children } />);

      const cookie = Cookies.get("webconfigurator")
      if (cookie)
      {
        console.log(cookie)
        securityToken = cookie
      }
    }
    catch { }

    const windowUrl = window.location.search;
    let params = new URLSearchParams(windowUrl);
    const handoverToken = params.get("handover_token")
    try { params.delete("handover_token") } catch {}

    let windowUrlWithoutHandoverToken = window.location.origin
    if (params.size > 0)
    {
      windowUrlWithoutHandoverToken += "?" + `${params}`
    }

    if (securityToken && !handoverToken)
    {
    return (
      <Route
      {...rest}
      render={({ location }) => children }
    />
    );
    }
    else
    {   
      const redirecturl = windowUrlWithoutHandoverToken.replaceAll(":","%3A").replaceAll("/","%2F").replaceAll("&","%26")   
      window.location.href = "https://signin-va.cloudcti.nl/signin/onelogin?clientid=webconfigurator&redirecturl=" + redirecturl + (branding_signinmethod ? "&signinmethod=" + branding_signinmethod : "") + (handoverToken ? "&handover_token=" + handoverToken : "")
      return null;
    }
  }

  // A wrapper for <Route> that redirects to the login
  // screen if you're not yet authenticated.
  function PublicRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          children
        }
      />
    );
  }

  AppyBrand()
  return (
    <Router>
      <div>
        <Switch>
          <PublicRoute path="/IntegrationWizard">
            <IntegrationWizardWrapper />
          </PublicRoute>
          <PublicRoute path="/ScriptWizard">
            <ScriptWizardWrapper />
          </PublicRoute>
          <AuthRoute>
            <Main />
          </AuthRoute>
        </Switch>
        </div>
    </Router>
  );
}


export default App;
