import React, {useEffect} from "react";
import PropTypes from 'prop-types';
import IntegrationWizardStepReviewScript from "./IntegrationWizardStepReviewScript.jsx"; //Step 11
import { Translate } from "../Translator";
import { GetDatasourceName } from "../Datasources/Datasources.jsx"
import IntegrationWizardStepHeader from "./IntegrationWizardStepHeader.jsx";

const IntegrationWizardStepReview = (props) => {

    useEffect(() => {        
        props.OnNextButtonEnabled(true)
      }, []); 

    return (
        <span>
          <IntegrationWizardStepHeader Header={Translate("Review your integration")}/>

          <div className="surface namevalue">
            <table width="100%" cellPadding={16}>
            <tbody>
            <tr>
              <td width="200" className="namevaluenamecolumn surfacedividerbottom">{Translate("Application")}</td>
              <td width="*" className="surfacedividerbottom">{props.IntegrationSettings.WizardSettings.application ? props.IntegrationSettings.WizardSettings.application : GetDatasourceName(props.IntegrationSettings.DatasourceType)}</td>
            </tr>
            <tr>
              <td className="namevaluenamecolumn surfacedividerbottom">{Translate("Recognition")}</td>
              <td className="surfacedividerbottom">{props.IntegrationSettings.DatasourceType === "none" ? Translate("No recognition has been configured") : Translate("Recognition from") + " " + props.IntegrationSettings.DatasourceType}</td>
            </tr>
            <tr>
              <td className="namevaluenamecolumn">{Translate("Scripts")}</td>
              <td>{props.IntegrationSettings.Scripts.map((script,index) => <IntegrationWizardStepReviewScript key={"script" + index} Script={script}/>)}</td>
            </tr>
            </tbody>
            </table>
          </div>
        </span>
    );
}

IntegrationWizardStepReview.propTypes = {
  IntegrationSettings: PropTypes.object,
  OnNextButtonEnabled: PropTypes.func
}

export default IntegrationWizardStepReview