import React, {useState} from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from 'prop-types';
import IntegrationWizardStepScriptItemCSD from './IntegrationWizardStepScriptItemCSD.jsx'
import IntegrationWizardStepScriptItemNoType from './IntegrationWizardStepScriptItemNoType.jsx'
import IntegrationWizardStepScriptItemWebPage from './IntegrationWizardStepScriptItemWebPage.jsx'
import { ExecuteScript } from '../ScriptExecuter.jsx'
import { GetLanguageCode, Translate } from "../Translator";

import DeletePng from '../../Assets/Delete.png';
import RemovePng from '../../Assets/Remove.png';
import DeleteDisabledPng from '../../Assets/DeleteDisabled.png';

const IntegrationWizardStepScriptItem = (props) => {

    const [mAutoExecute, SetAutoExecute] = useState(props.Script.AutoExecute) //used only as a repainter
    const [mScriptName, SetScriptName] = useState(props.Script.Name) //used only as a repainter
    const [mScriptTest, SetScriptTest] = useState(false) //modal toggle
    const [mScriptDelete, SetScriptDelete] = useState(false) //modal toggle
    const [mFolded, SetFolded] = useState(props.Folded) 
    
    const [mScriptResult, SetScriptResult] = useState(null) 

    const [mCallFieldCallerNumber, SetCallFieldCallerNumber] = useState("0123456789"); 
    const [mCallFieldCallerName, SetCallFieldCallerName] = useState("test number"); 
    const [mCallFieldDeviceNumber, SetCallFieldDeviceNumber] = useState("100"); 
    const [mCallFieldDeviceName, SetCallFieldDeviceName] = useState("test device"); 
    const [mCallFieldDdiNumber, SetCallFieldDdiNumber] = useState("100"); 
    const [mCallFieldDdiName, SetCallFieldDdiName] = useState("test ddi"); 

    const TestScript = () => {

        const CallFields = {
            "Caller number": mCallFieldCallerNumber, 
            "Caller name": mCallFieldCallerName,
            "Device number": mCallFieldDeviceNumber, 
            "Device name": mCallFieldDeviceName, 
            "Ddi number": mCallFieldDdiNumber, 
            "Ddi name": mCallFieldDdiName,
            "Start time": new Date(), 
            "Application name": "" 
        }

        const ScriptResult = ExecuteScript(props.SecurityToken, props.Script, CallFields, props.DatasourceFields)
        if (ScriptResult === null) 
        {
            ScriptResult = ""
        }
        SetScriptResult(ScriptResult)

        console.log("Script result: " + (ScriptResult ? ScriptResult : "ok"))

        SetScriptTest(false)
    }

    const HelpCaption = () => {
        if (props.PredefinedScript)
        {
            const LanguageCode = GetLanguageCode() ?? "default"  
            if (props.PredefinedScript.Help)
            {
                return props.PredefinedScript.Help[LanguageCode]
            }
        }
        return "";
    }

    const UpdateAutoExecute = (newValue) =>
    {
        props.Script.AutoExecute = (newValue ? true : false)
        SetAutoExecute(newValue)
    }

    const UpdateScriptName = (newValue) =>
    {
        props.Script.Name = newValue
        SetScriptName(newValue)
    }

    const UpdateScriptEvent = () =>
    {
        SetScriptResult(null)
    }

    const ScriptBody = ((props.Script.ScriptType === "") ? <IntegrationWizardStepScriptItemNoType Script={props.Script}/> :
        (((props.Script.ScriptType === "CSD") || (props.Script.ScriptType === "ServerCSD")) ? <IntegrationWizardStepScriptItemCSD Script={props.Script} PredefinedScript={props.PredefinedScript} OnScriptChange={UpdateScriptEvent}/> :
        ((props.Script.ScriptType === "OpenWebPage") ? <IntegrationWizardStepScriptItemWebPage DatasourceFields={props.DatasourceFields} Script={props.Script} PredefinedScript={props.PredefinedScript} OnScriptChange={UpdateScriptEvent}/> : 
        <div></div>)))

    const BadScript = (props.Script.ScriptType !== "OpenWebPage") && (props.Script.ScriptType !== "ServerCSD")
    
    const BrandingId = sessionStorage.getItem("BrandingId")

    return (
        <span>
            <div className="surface wizardsurface">
                <table width="100%" cellPadding="20" cellSpacing="0">
                <tbody>
                <tr>
                    <td>
                        {BadScript ?
                        
                        <table width="100%" cellPadding="0" cellSpacing="0">
                        <tbody>
                        <tr>
                            <td width="30"></td>
                            <td width="40" valign="center">
                                <Button variant="outline-dark" onClick={() => SetScriptDelete(true)} className={BrandingId === "enreach" ? "brandbuttonoutline" : "brandbuttonkeeporiginalstyle"} style={{ padding: '6px 10px' }}><img src={RemovePng} className="wizardscriptitemremove"/></Button>
                            </td>
                            <td width="20"/>
                            <td width="*" valign="center">{props.Script.Name}</td>
                            <td width="300" valign="center" align="right">
                                Editing not available in web app
                            </td>
                        </tr>
                        </tbody>
                        </table>  

                        :

                        mFolded 
                        
                        ?

                        <table width="100%" cellPadding="0" cellSpacing="0">
                        <tbody>
                        <tr>
                            <td width="50" valign="center">
                                <Button variant="outline-dark" onClick={() => SetScriptDelete(true)} className={BrandingId === "enreach" ? "brandbuttonoutline" : "brandbuttonkeeporiginalstyle"} style={{ padding: '6px 10px' }}><img src={RemovePng} className="wizardscriptitemremove"/></Button>
                            </td>
                            <td width="20"/>
                            <td width="*" valign="center">{props.Script.Name}</td>
                            <td width="300" valign="center" align="right">
                                <Button variant="outline-dark" className={BrandingId === "enreach" ? (mScriptResult === null ? "brandbuttonoutline" : (mScriptResult === "" ? "brandbuttonoutline brandbuttonscriptok" : "brandbuttonoutline brandbuttonscriptfailed")) : (mScriptResult === null ? "brandbuttonkeeporiginalstyle" : (mScriptResult === "" ? "brandbuttonkeeporiginalstyle brandbuttonscriptok" : "brandbuttonkeeporiginalstyle brandbuttonscriptfailed"))} onClick={() => SetScriptTest(true)}>{Translate("Test script")}</Button>
                                &nbsp;&nbsp;&nbsp;
                                <Button variant="dark" className={BrandingId === "enreach" ? "brandbuttonprimary" : "brandbuttonkeeporiginalstyle"} onClick={() => SetFolded(!mFolded)}>Edit script</Button>
                            </td>
                        </tr>
                        </tbody>
                        </table>   

                        :

                        <table width="100%" cellPadding="0" cellSpacing="0">
                        <tbody>
                        <tr>
                            <td colSpan="2">
                                {props.Script.SubHeader ? <div className="wizardscriptsubheader">{props.Script.SubHeader}</div> : ""}
                                <div className="wizardfieldname">{Translate("Script name")} <span className="redtext">*</span></div>
                                <div className="wizardfieldvalue"><input autoComplete="new-password" placeholder="" type="text" value={props.Script.Name} onChange={evt => UpdateScriptName(evt.target.value)} className="wizardinputfield"/></div>    
                                {ScriptBody}
                                {props.Script.ScriptType === "ServerCSD" ? <span/> : 
                                
                                    <table cellPadding="2">
                                    <tbody>
                                        <tr>
                                            <td width="30"><span className="checkbox-wrapper-13"><input type="checkbox" checked={props.Script.AutoExecute ? "checked" : ""} onChange={evt => UpdateAutoExecute(evt.target.checked)}/></span></td>
                                            <td width="*">{Translate("Auto-execute")}</td>
                                        </tr>
                                    </tbody>
                                    </table>
                                }
                            </td>                   
                        </tr>
                        <tr>
                            <td/>
                            <td align="right" valign="bottom">

                                {props.HideSave ? null : <Button variant="outline-dark" onClick={() => SetFolded(!mFolded)} className={BrandingId === "enreach" ? "brandbuttonoutline" : "brandbuttonkeeporiginalstyle"}>{Translate("Stop editing")}</Button>}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button variant="outline-dark" onClick={() => SetScriptTest(true)} className={BrandingId === "enreach" ? (mScriptResult === null ? "brandbuttonoutline" : (mScriptResult === "" ? "brandbuttonoutline brandbuttonscriptok" : "brandbuttonoutline brandbuttonscriptfailed")) : (mScriptResult === null ? "brandbuttonkeeporiginalstyle" : (mScriptResult === "" ? "brandbuttonkeeporiginalstyle brandbuttonscriptok" : "brandbuttonkeeporiginalstyle brandbuttonscriptfailed"))}>{Translate("Test script")}</Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button variant="outline-dark" onClick={() => SetScriptDelete(true)} className={BrandingId === "enreach" ? "brandbuttonoutline" : "brandbuttonkeeporiginalstyle"} style={{ padding: '6px 10px' }}><img src={RemovePng} className="wizardscriptitemremove"/></Button>
                                
                            </td>                   
                        </tr>
                        </tbody>
                        </table>}   

                    </td>
                </tr>
                </tbody>
                </table>    
            </div>                

            <Modal animation={false} show={mScriptTest} onHide={() => SetScriptTest(false)}>
                <Modal.Header className="surfacedividerbottom" closeButton>
                    <Modal.Title className="wizardstepheader">{Translate("Enter the call field values to test the script with")}</Modal.Title>
                </Modal.Header>
                <Modal.Body align="center">
                    <div align="center" className="surface namevalue"> 
                        <table width="100%" cellPadding={8}>  
                        <tbody>                
                                <tr>
                                    <td width="150" className="namevaluenamecolumn surfacedividerbottom">{Translate("Caller number")}</td> 
                                    <td width="*" className="surfacedividerbottom"><input autoComplete="new-password" type="text" className="wizardinputfield testscriptinput" value={mCallFieldCallerNumber} onChange={evt => SetCallFieldCallerNumber(evt.target.value)}/></td>
                                </tr>           
                                <tr>
                                    <td width="150" className="namevaluenamecolumn surfacedividerbottom">{Translate("Caller name")}</td> 
                                    <td width="*" className="surfacedividerbottom"><input autoComplete="new-password" type="text" className="wizardinputfield testscriptinput" value={mCallFieldCallerName} onChange={evt => SetCallFieldCallerName(evt.target.value)}/></td>
                                </tr>           
                                <tr>
                                    <td width="150" className="namevaluenamecolumn surfacedividerbottom">{Translate("Device number")}</td> 
                                    <td width="*" className="surfacedividerbottom"><input autoComplete="new-password" type="text" className="wizardinputfield testscriptinput" value={mCallFieldDeviceNumber} onChange={evt => SetCallFieldDeviceNumber(evt.target.value)}/></td>
                                </tr>           
                                <tr>
                                    <td width="150" className="namevaluenamecolumn surfacedividerbottom">{Translate("Device name")}</td> 
                                    <td width="*" className="surfacedividerbottom"><input autoComplete="new-password" type="text" className="wizardinputfield testscriptinput" value={mCallFieldDeviceName} onChange={evt => SetCallFieldDeviceName(evt.target.value)}/></td>
                                </tr>           
                                <tr>
                                    <td width="150" className="namevaluenamecolumn surfacedividerbottom">{Translate("Ddi number")}</td> 
                                    <td width="*" className="surfacedividerbottom"><input autoComplete="new-password" type="text" className="wizardinputfield testscriptinput" value={mCallFieldDdiNumber} onChange={evt => SetCallFieldDdiNumber(evt.target.value)}/></td>
                                </tr>           
                                <tr>
                                    <td width="150" className="namevaluenamecolumn">{Translate("Ddi name")}</td> 
                                    <td width="*"><input autoComplete="new-password" type="text" className="wizardinputfield testscriptinput" value={mCallFieldDdiName} onChange={evt => SetCallFieldDdiName(evt.target.value)}/></td>
                                </tr>
                        </tbody>       
                        </table> 
                    </div>
                    <br/>
                    <Button variant="primary" className={BrandingId === "enreach" ? "brandbuttonprimary" : "brandbuttonkeeporiginalstyle"} onClick={() => TestScript()} style={{ padding: '12px 50px' }}>{Translate("Execute script")}</Button>
                </Modal.Body>
            </Modal>
            <Modal animation={false} show={mScriptDelete} onHide={() => SetScriptDelete(false)} centered={true}>
                <Modal.Header>
                    <Modal.Title><span className="wizardstepheader" align="center">{Translate("Do you really want to remove this script?")}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body align="center">
                    <Button variant="outline-dark" className={BrandingId === "enreach" ? "brandbuttonprimary" : "brandbuttonkeeporiginalstyle"} onClick={() => SetScriptDelete(false)} style={{ padding: '12px 50px' }}>No</Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="dark" className={BrandingId === "enreach" ? "brandbuttonprimary" : "brandbuttonkeeporiginalstyle"} onClick={() => props.OnDelete()} style={{ padding: '12px 50px' }}>Yes</Button>
                </Modal.Body>
            </Modal>
        </span>
    );
}

IntegrationWizardStepScriptItem.propTypes = {
    Folded: PropTypes.bool,
    HideSave: PropTypes.bool,
    SecurityToken: PropTypes.string,
    Script: PropTypes.object,
    PredefinedScript: PropTypes.object,
    DatasourceFields: PropTypes.array,
    OnDelete: PropTypes.func
}

export default IntegrationWizardStepScriptItem