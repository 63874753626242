import React, {useState} from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import { Button } from "react-bootstrap";
import { ConfigurationApiDatasourceInfo } from "../../ExternalApi/ConfigurationApi"

const DatasourceTheOfficeManager = (props) => {

    const [mRepainter, SetRepainter] = useState(false); 

    const UpdateDatasourceSetting = (aNewValue, aIdentifier) =>
    {
        SetRepainter(!mRepainter)
        props.IntegrationSettings.DatasourceSettings[aIdentifier] = aNewValue

        SetReady()
    }

    const GetReady = () => 
    {
        return (props.IntegrationSettings.DatasourceSettings["TenantApiGuid"])
            && (props.IntegrationSettings.DatasourceSettings["UserApiGuid"])
            && (props.IntegrationSettings.DatasourceSettings["Domain"])
    }

    const SetReady = () => 
    {
        props.OnSetReady(GetReady())
    }

    async function RequestCustomFields()  {
        try 
        {
            const result = await ConfigurationApiDatasourceInfo(props.SecurityToken, props.IntegrationSettings.DatasourceType, "PhoneFields", props.IntegrationSettings.DatasourceSettings)
            if (result.status === 200)
            {
                props.IntegrationSettings.DatasourceSettings["CustomPhoneFields"] = result.data.Info
                SetRepainter(!mRepainter)
            }        
        } 
        catch (ex) 
        {    
            console.log(ex)
            return false
        }
    }
    
    const BrandingId = sessionStorage.getItem("BrandingId")

    return (
        <div className="scriptitembody">

                <div className="wizardfieldname">TENANT API GUID<span className="redtext">*</span></div>
                <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings["TenantApiGuid"] || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "TenantApiGuid")} className="wizardinputfield"/></div>
                <br clear="all"/>
                <div className="wizardfieldname">USER API GUID <span className="redtext">*</span></div>
                <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings["UserApiGuid"] || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "UserApiGuid")} className="wizardinputfield"/></div>
                <br clear="all"/>
                <div className="wizardfieldname">{Translate("Domain").toUpperCase()} <span className="redtext">*</span></div>
                <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings["Domain"] || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "Domain")} className="wizardinputfield"/></div>
                <br clear="all"/>
                <div className="wizardfieldname">{Translate("Custom phone fields").toUpperCase()} <span className="redtext">*</span></div>
                <div className="wizardfieldvalue">
                    <input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings["CustomPhoneFields"] || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "CustomPhoneFields")} className="wizardinputfield"/>
                    <Button variant="primary" className={BrandingId === "enreach" ? "brandbuttonprimary" : "brandbuttonkeeporiginalstyle"} onClick={() => RequestCustomFields()} disabled={!GetReady()} type="submit">Auto-detect</Button>
                </div>
        </div>
    )

}

DatasourceTheOfficeManager.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceTheOfficeManager