import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";

import EnreachPng from '../Assets/branding_icon_enreach.png';
import Loading from './Loading.jsx'
import IntegrationWizardStepScriptItem from './IntegrationWizard/IntegrationWizardStepScriptItem.jsx'
import { ConfigurationApiCallFieldScriptsAdd,ConfigurationApiCallFieldScriptsUpdate } from "../ExternalApi/ConfigurationApi.js"
import { GetDelphiDateTimeNow } from "./GeneralFunctions.jsx"
import { Translate } from "./Translator";

import IntegrationWizardStepHeader from "./IntegrationWizard/IntegrationWizardStepHeader.jsx"
import IntegrationWizardNavigation from "./IntegrationWizard/IntegrationWizardNavigation.jsx"

import "../css/IntegrationWizard.css";

const ScriptWizard = (props) => {

    const mHistory = useHistory();

    const [mWizardMessage, SetWizardMessage] = useState(null);
    const [mScript, SetScript] = useState(null)

    useEffect(() => {
        if (props.Action === "update")
        {
            SetScript(props.Scripts[props.ScriptIndex])
        } 
        else
        {
            SetScript(null)
        }
      }, []);

    const OnCancelButtonClicked = async () => {      
        if (props.After === "main")
        { 
            mHistory.push("/main")
        }
        else
        {
            SetWizardMessage("The wizard was cancelled. You can close this window.")
        }
    } 

    const OnOKButtonClicked = async () => {   
        
        mScript.LastModified = GetDelphiDateTimeNow()

        if (props.Action === "update")
        {
            props.Scripts[props.ScriptIndex] = mScript
            await ConfigurationApiCallFieldScriptsUpdate(props.SecurityToken, props.Scripts)
        }
        else 
        {
            await ConfigurationApiCallFieldScriptsAdd(props.SecurityToken, [mScript])
            localStorage.setItem("SelectedIntegrationGuid", "");
            localStorage.setItem("SelectedCallfieldScript", "last");
        }
        
        if (props.After === "main")
        { 
            mHistory.push("/main")
        }
        else
        {
            window.opener.location.reload(true)
            window.opener = null
            //window.open("", "_self")
            window.close()
        }
    } 

    const NewScript = () => {
        let Script = {}
        Script.Name = Translate("My Custom Script")
        Script.SubHeader = ""
        Script.XmlFile = ""
        Script.XmlApplication = ""
        Script.ScriptType = "OpenWebPage"
        Script.Enabled = true
        Script.CSDFileName = ""
        Script.CSDParams = {}
        Script.WebPage = "https://www.google.nl?q=#(Caller number)"
        Script.IncludeRecognitionFields = true
        Script.IncludeCallFields = true
        Script.AutoExecute = false   
        return Script
    }

    //Using a variable because mScript does not immediately change after calling SetScript()
    let Script = mScript
    if (!Script || !Script.ScriptType)
    {
        Script = NewScript()
        SetScript(Script)
    }
    
    const BrandingId = sessionStorage.getItem("BrandingId")

    //Paint

    if (mWizardMessage !== null)
    {
        return (
            <div className="loading-outer">
              <div className="loading-inner">
                {mWizardMessage}
              </div>
            </div>
        ); 
    }
    else if (!mScript)
    {
        return <Loading/>
    }
    else
    {
        return ( 
            <table className="main" cellPadding="0" cellSpacing="0">  
            <tbody>
            <tr height="0">
                <td width="*"/>
                <td width="1200"/>
                <td width="*"/>
            </tr>  
            <tr height="16" className="pagetop" style={BrandingId === "enreach" ? {} : {display: 'none'}}><td colSpan="3"/></tr> 
            <tr className="pagetop" style={BrandingId === "enreach" ? {} : {display: 'none'}}>
                <td colSpan="3">&nbsp;&nbsp;&nbsp;&nbsp;<img src={EnreachPng}/> &nbsp; Webconfigurator</td>
            </tr>  
            <tr height="16" className="pagetop" style={BrandingId === "enreach" ? {} : {display: 'none'}}><td colSpan="3"/></tr> 
            <tr height="10" />
            <tr>
                <td></td>
                <td>
                    <br/>
                        <IntegrationWizardStepHeader Header="Scripts" SubHeader={Translate("Configure the action do you want to perform")}/>
                        <IntegrationWizardStepScriptItem Folded={false} HideSave={true} SecurityToken={props.SecurityToken} Script={mScript} PredefinedScript={props.PredefinedScripts.find(pscript => pscript.Id === mScript.XmlFile)} OnDelete={null}/>
                    <br/>
                </td>
                <td></td>
            </tr>
            <tr height="20"/>
            <tr height="70">
                <td className="wizardfooter"></td>
                <td className="wizardfooter">
                    <IntegrationWizardNavigation BackVisible={false} NextVisible={true} NextEnabled={true} NextCaption={Translate("FINISH")} OnBack={null} OnNext={OnOKButtonClicked}/>
                </td>
                <td className="wizardfooter"></td>
            </tr>
            <tr height="10"><td/></tr>
            <tr>
                <td></td>
                <td className="copyrightbottom" align="center" valign="bottom">© 2024 CloudCTI</td>
                <td></td>
            </tr>
            </tbody>
            </table>
        ); 
    }
};

ScriptWizard.propTypes = {
    SecurityToken: PropTypes.string,
    Action: PropTypes.string,
    Scripts: PropTypes.array,
    ScriptIndex: PropTypes.number,
    After: PropTypes.string,
    PredefinedScripts: PropTypes.array
  }

export default ScriptWizard;