import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import PdfPng from '../../Assets/Pdf.png';

const DatasourceCarerix = (props) => {
    
    const [mRepainter, SetRepainter] = useState(false); 

    useEffect(() => {
        props.IntegrationSettings.DatasourceSettings.DatasourceVersion = "2"
        SetReady()
      }, []); 
      
    const UpdateDatasourceSetting = (aNewValue, aIdentifier) =>
    {
        SetRepainter(!mRepainter)
        props.IntegrationSettings.DatasourceSettings[aIdentifier] = aNewValue

        SetReady()
    }

    const SetReady = () => 
    {
        if (props.IntegrationSettings.DatasourceSettings.SystemName
            && props.IntegrationSettings.DatasourceSettings.AccessToken
            && (props.IntegrationSettings.DatasourceSettings.IncludeUsers 
                || props.IntegrationSettings.DatasourceSettings.IncludeEmployees
                || props.IntegrationSettings.DatasourceSettings.IncludeCompanies
                || props.IntegrationSettings.DatasourceSettings.IncludeContacts))
        {
            props.OnSetReady(true)
        }
        else
        {
            props.OnSetReady(false)
        }
    }
    
    return (
        <div className="scriptitembody">
            <div className="wizardfieldname">{Translate("System name").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings.SystemName || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "SystemName")} className="wizardinputfield"/></div>
            <div className="wizardfieldname">{Translate("Access token").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings.AccessToken || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "AccessToken")} className="wizardinputfield"/></div>
            <br/>
            <table cellPadding="2">
            <tbody>
                <tr>
                    <td width="30"><span className="checkbox-wrapper-13"><input type="checkbox" checked={props.IntegrationSettings.DatasourceSettings.IncludeUsers ? "checked" : ""} onChange={evt => UpdateDatasourceSetting(evt.target.checked, "IncludeUsers")}/></span></td>
                    <td width="*">{Translate("Include users")}</td>
                </tr>
                <tr>
                    <td><span className="checkbox-wrapper-13"><input type="checkbox" checked={props.IntegrationSettings.DatasourceSettings.IncludeEmployees ? "checked" : ""} onChange={evt => UpdateDatasourceSetting(evt.target.checked, "IncludeEmployees")}/></span></td>
                    <td>{Translate("Include employees")}</td>
                </tr>
                <tr>
                    <td><span className="checkbox-wrapper-13"><input type="checkbox" checked={props.IntegrationSettings.DatasourceSettings.IncludeCompanies ? "checked" : ""} onChange={evt => UpdateDatasourceSetting(evt.target.checked, "IncludeCompanies")}/></span></td>
                    <td>{Translate("Include companies")}</td>
                </tr>
                <tr>
                    <td><span className="checkbox-wrapper-13"><input type="checkbox" checked={props.IntegrationSettings.DatasourceSettings.IncludeContacts ? "checked" : ""} onChange={evt => UpdateDatasourceSetting(evt.target.checked, "IncludeContacts")}/> </span></td>
                    <td>{Translate("Include contacts")}</td>
                </tr>
            </tbody>
            </table>
        </div>
    );
}

DatasourceCarerix.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceCarerix