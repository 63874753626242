import React from "react";
import PropTypes from 'prop-types';

import PdfPng from '../../Assets/Pdf.png';

const IntegrationWizardStepHeader = (props) => {

    const OpenPdf = () => {
        window.open(props.PdfUrl, "_blank")
    }

    return (        
        <div>
            <div className="wizardstepheader">
                {props.Header}
                {props.PdfUrl ? <span>&nbsp;<img src={PdfPng} alt="pdf" width="13" height="13" className="linktext" onClick={OpenPdf}/></span> : ""}
            </div>
            {props.SubHeader ? <div className="wizardstepsubheader">{props.SubHeader}</div> : ""}
            <table><tbody><tr height="10"/></tbody></table>
            <table cellPadding="0" cellSpacing="0" className="separatorbar">
            <tbody>
                <tr>
                    <td className="separatorbar"></td>
                </tr>
            </tbody>     
            </table>
            <br/>
            <table><tbody><tr height="10"/></tbody></table>
        </div>
    );
}

IntegrationWizardStepHeader.propTypes = {
  Header: PropTypes.string,
  SubHeader: PropTypes.string,
  PdfUrl: PropTypes.string
}

export default IntegrationWizardStepHeader