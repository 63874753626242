import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

import { Translate } from "../Translator.jsx"

import SelectedHeaderPng from '../../Assets/SelectedHeader.png';
import Step1Png from '../../Assets/Step1.png';
import Step2Png from '../../Assets/Step2.png';
import Step3Png from '../../Assets/Step3.png';
import Step4Png from '../../Assets/Step4.png';
import Step5Png from '../../Assets/Step5.png';

import "../../css/IntegrationWizard.css";

const IntegrationWizardHeaderEnreach = (props) => {

    const IsWizardHeaderDisabled = (aHeader) => {
        if (aHeader === "Choose preset") { return props.Action === "update" || props.Action === "setcountrycode" || props.Action === "setnotification" || props.Action === "setscripts"}
        else if (aHeader === "CRM configuration") { return props.Action === "setnotification" || props.Action === "setscripts"}
        else if (aHeader === "Call notification") { return props.Action === "setcountrycode" || props.Action === "setscripts"}
        else if (aHeader === "Scripts") { return props.Action === "setcountrycode" || props.Action === "setnotification"}
        else if (aHeader === "Review") { return props.Action === "setcountrycode" || props.Action === "setnotification" || props.Action === "setscripts"}
        else {return false}
    }

    //Determines which step in the top menu is active
    const MenuStep = (props.CurrentStep > 9) ? 5 :
        ((props.CurrentStep > 8) ? 4 :
            ((props.CurrentStep > 7) ? 3 :
                ((props.CurrentStep > 3) ? 2 : 1)))

    //Paint

    const PresetColor = MenuStep === 1 ? '#8A54B8' : '#E6D7EF'
    const CRMConfigurationColor = MenuStep === 2 ? '#8A54B8' : (MenuStep > 2 ? '#E6D7EF' : '#CFCED0')
    const CallNotificationColor = MenuStep === 3 ? '#8A54B8' : (MenuStep > 3 ? '#E6D7EF' : '#CFCED0')
    const ScriptsColor = MenuStep === 4 ? '#8A54B8' : (MenuStep > 4 ? '#E6D7EF' : '#CFCED0')
    const ReviewColor = MenuStep === 5 ? '#8A54B8' : '#CFCED0'
    
    return ( 

        <table width="100%" height="64" cellSpacing="0" style={{ background: '#FCFCFC', font: 'bold 16px "Open Sans",sans-serif' }}>
        <tbody>
        <tr height="20"></tr>  
        <tr>
            <td width={(window.innerWidth > 800) ? "20%" : ((MenuStep === 1) ? "*" : "50")} align="left" valign="center">
                <table width="100%" cellPadding="1" cellSpacing="6">
                <tbody>
                <tr height="10">
                    <td style={{ backgroundColor: PresetColor, borderRadius: '6px'}}/>
                </tr>
                <tr>
                    <td align="left" style={{ color: PresetColor }}>1. {Translate("Choose preset")}</td>
                </tr>
                </tbody>
                </table>
            </td>
            <td width={(window.innerWidth > 800) ? "20%" : ((MenuStep === 2) ? "*" : "50")} height="100%" align="left" valign="center">
                <table width="100%" cellPadding="1" cellSpacing="6">
                <tbody>
                <tr height="10">
                    <td style={{ backgroundColor: CRMConfigurationColor, borderRadius: '6px' }}></td>
                </tr>
                <tr>
                    <td align="left" style={{ color: CRMConfigurationColor }}>2. {Translate("CRM configuration")}</td>
                </tr>
                </tbody>
                </table>                      
            </td>
            <td width={(window.innerWidth > 800) ? "20%" : ((MenuStep === 2) ? "*" : "50")} height="100%" align="left" valign="center">
                <table width="100%" cellPadding="1" cellSpacing="6">
                <tbody>
                <tr height="10">
                    <td style={{ backgroundColor: CallNotificationColor, borderRadius: '6px' }}></td>
                </tr>
                <tr>
                    <td align="left" style={{ color: CallNotificationColor }}>3. {Translate("Call notification")}</td>
                </tr>
                </tbody>
                </table>                      
            </td>
            <td width={(window.innerWidth > 800) ? "20%" : ((MenuStep === 2) ? "*" : "50")} height="100%" align="left" valign="center">
                <table width="100%" cellPadding="1" cellSpacing="6">
                <tbody>
                <tr height="10">
                    <td style={{ backgroundColor: ScriptsColor, borderRadius: '6px' }}></td>
                </tr>
                <tr>
                    <td align="left" style={{ color: ScriptsColor }}>4. {Translate("Scripts")}</td>
                </tr>
                </tbody>
                </table>                      
            </td>
            <td width={(window.innerWidth > 800) ? "20%" : ((MenuStep === 2) ? "*" : "50")} height="100%" align="left" valign="center">
                <table width="100%" cellPadding="1" cellSpacing="6">
                <tbody>
                <tr height="10">
                    <td style={{ backgroundColor: ReviewColor, borderRadius: '6px' }}></td>
                </tr>
                <tr>
                    <td align="left" style={{ color: ReviewColor }}>5. {Translate("Review")}</td>
                </tr>
                </tbody>
                </table>                      
            </td>
        </tr>
        <tr height="20"></tr>  
        </tbody>
        </table>
    )
}

IntegrationWizardHeaderEnreach.propTypes = {
    Action: PropTypes.string,
    CurrentStep: PropTypes.number
  }

export default IntegrationWizardHeaderEnreach;