import React, {useCallback, useEffect, useState, useRef} from "react";
import PropTypes from 'prop-types';
import { format } from "date-fns";
import { Translate } from "../Translator";
import DatasourceBaseOauth from "../Datasources/DatasourceBaseOauth.jsx";
import { ConfigurationApiDatasourceInfo } from "../../ExternalApi/ConfigurationApi"

const DatasourceExactOnline = (props) => {

    const [mServers, SetServers] = useState([])  
    const [mSelectedServerIndex, SetSelectedServerIndex] = useState(-1) 
        
    const [mAuthUrl, SetAuthUrl] = useState("")  
    const [mTokenUrl, SetTokenUrl] = useState("")  
    const [mTokenBody, SetTokenBody] = useState("") 

    const [mDivisions, SetDivisions] = useState([]) 
    const [mSettingDivisions, SetSettingDivisions] = useState([]) 

    useEffect(() => {

        async function GetServers() {
            try 
            {
                const result = await ConfigurationApiDatasourceInfo(props.SecurityToken, props.IntegrationSettings.DatasourceType, "Servers", props.IntegrationSettings.DatasourceSettings)
                if (result.status === 200)
                {
                    return result.data.Info
                }        
            } 
            catch (ex) 
            {    
                console.log(ex)
                return false
            }
        }
  
        async function Initialize() {  

            const DateNow = new Date()
            const DateExpires = new Date(DateNow + (1000 * 900))
            const DateExpiresFormatted = format(DateExpires, "yyyy-MM-dd HH:mm:ss");
            console.log(DateExpiresFormatted)

            const Servers = await GetServers()
            SetServers(Servers) 

            if (Servers.length > 0)
            {
                SetSelectedServer(0,Servers)
            }
        }
  
        Initialize()
    }, []);

    function SetSelectedServer(aSelectedServerIndex, aServers)
    {
        //Clear settings
        SetDivisions([])
        props.IntegrationSettings.DatasourceSettings = {}

        //Initialize
        const Server = aServers[aSelectedServerIndex]

        SetAuthUrl(Server.ServerUrl + "/api/oauth2/auth" +
            "?client_id=" + Server.ClientId + 
            "&redirect_uri=" + "https%3A%2F%2Fauth.cloudcti.nl%2Fwebapi%2Foauth2" +
            "&scope=" + 
            "&response_type=code")

        SetTokenUrl(Server.ServerUrl + "/api/oauth2/token")

        SetTokenBody("client_id=" + Server.ClientId +
            "&client_secret=" + Server.ClientSecret +
            "&redirect_uri=" + "https%3A%2F%2Fauth.cloudcti.nl%2Fwebapi%2Foauth2" +
            "&grant_type=authorization_code" + 
            "&code=") 

        props.IntegrationSettings.DatasourceSettings.ServerUrl = Server.ServerUrl
        SetSelectedServerIndex(aSelectedServerIndex)
    }

    async function OAuthComplete(aFlag)
    {
        if (aFlag)
        {
            //Zou eigenlijk in de oauth moeten met de expires_in
            const DateNow = new Date()
            const DateExpires = new Date(DateNow + (1000 * 900))
            const DateExpiresFormatted = format(DateExpires, "yyyy-MM-dd HH:mm:ss")
            props.IntegrationSettings.DatasourceSettings.AccessTokenExpires = DateExpiresFormatted

            try 
            {
                const result = await ConfigurationApiDatasourceInfo(props.SecurityToken, props.IntegrationSettings.DatasourceType, "Divisions", props.IntegrationSettings.DatasourceSettings)
                if (result.status === 200)
                {
                    SetSettingDivisions(result.data.Info) //Select all by default
                    props.IntegrationSettings.DatasourceSettings.Divisions = SettingsDivisionsCommaText(result.data.Info) 
                    SetDivisions(result.data.Info)
                }        
            } 
            catch (ex) 
            {    
                console.log("OAuthComplete GetInfoError: " + JSON.stringify(ex))
                return
            }
        }
            
        props.OnSetReady(aFlag)
    }

    function SettingsDivisionsCommaText(aDivisions)
    {
        let Result = ""
        aDivisions.map(division => (Result === "" ? Result = Result + division.Division : Result = Result + "," + division.Division))
        return Result
    }

    function SettingsHasDivision(aDivision)
    {
        return mSettingDivisions.filter(division => (division.Name === aDivision.Name) && (division.Division === aDivision.Division)).length > 0
    }

    function SettingsUpdateDivisions(aFlag, aDivision)
    {
        if (!aFlag)
        {
            const Divisions = mSettingDivisions.filter(division => (division.Name !== aDivision.Name) || (division.Division !== aDivision.Division))
            SetSettingDivisions(Divisions)
            props.IntegrationSettings.DatasourceSettings.Divisions = SettingsDivisionsCommaText(Divisions)
        }
        else if (!SettingsHasDivision(aDivision))
        {
            const Divisions = [...mSettingDivisions, aDivision]
            SetSettingDivisions(Divisions)
            props.IntegrationSettings.DatasourceSettings.Divisions = SettingsDivisionsCommaText(Divisions)
        }
    }

    return mServers.length > 0 ?
        <div className="scriptitembody">
            <div className="wizardfieldname">{Translate("Region").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue">
                <select name="Region" value={mSelectedServerIndex} onChange={evt => SetSelectedServer(evt.target.value, mServers)} className="wizardinputfield">
                    {mServers.map((server, index) => <option key={"server" + index} value={index}>{server.Country}</option>)}
                </select>
            </div>

            <DatasourceBaseOauth SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} AuthUrl={mAuthUrl} TokenUrl={mTokenUrl} TokenBody={mTokenBody} TokenBodyType="application/x-www-form-urlencoded" TokenCodeInBody={true} OnSetReady={OAuthComplete}/>

            <table cellPadding="2">
            <tbody>
                {mDivisions.map((division, index) => 
                    <tr key={"division" + index}>
                        <td width="30"><span className="checkbox-wrapper-13"><input type="checkbox" checked={SettingsHasDivision(division) ? "checked" : ""} value={division} onChange={evt => SettingsUpdateDivisions(evt.target.checked, division)}/></span></td>
                        <td width="*">{division.Name}</td>
                    </tr>)}
            </tbody>
            </table>
        </div> :
        <div>Waiting...</div>
}

DatasourceExactOnline.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceExactOnline