import React, {createRef, useState} from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import IntegrationWizardStepHeader from "./IntegrationWizardStepHeader.jsx";
import IntegrationWizardStepClientNotificationPreview from "./IntegrationWizardStepClientNotificationPreview.jsx"
import Button from 'react-bootstrap/Button';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const IntegrationWizardStepClientNotification = (props) => {

  const [mRepainter, SetRepainter] = useState(false)
  const mDummyRef = createRef()

  const ReplaceFieldTypes = () => {
      let NewBody = [];

      for (let i = 0; i < props.IntegrationSettings.NotificationTemplate.Body.length; i++) 
      {
        let bodyLine = props.IntegrationSettings.NotificationTemplate.Body[i]

        if (bodyLine.includes("%(Name)"))
        {
            const Field = props.IntegrationSettings.DatasourceFields.filter(field => field.FieldType === "Name")
            if (Field.length > 0) 
            {
              bodyLine = bodyLine.replace("%(Name)","$(" + Field[0].FieldLabel + ")")
            }
            else 
            {
              bodyLine = bodyLine.replace("%(Name)","") 
            }           
        }

        if (bodyLine.includes("%(Organization)"))
        {
          const Field = props.IntegrationSettings.DatasourceFields.filter(field => field.FieldType === "Organization")
          if (Field.length > 0) 
          {
            bodyLine = bodyLine.replace("%(Organization)","$(" + Field[0].FieldLabel + ")")
          }
          else 
          {
            bodyLine = bodyLine.replace("%(Organization)","") 
          }  
        }    
        
        NewBody.push(bodyLine)
      } 

      props.IntegrationSettings.NotificationTemplate.Body = NewBody
    } 
    
    const ReplaceFields = (aLine) => {

      let bodyLine = aLine

      bodyLine = bodyLine.replace("#(Caller number)","0123456789")
      bodyLine = bodyLine.replace("#(Caller name)","John Doe")
      bodyLine = bodyLine.replace("#(Device number)","100")
      bodyLine = bodyLine.replace("#(Device name)","Employee 100")
      bodyLine = bodyLine.replace("#(Ddi number)","100")
      bodyLine = bodyLine.replace("#(Ddi name)","Ddi 100")
      bodyLine = bodyLine.replace("#(Application name)",props.IntegrationSettings.ApplicationName)

      {props.IntegrationSettings.DatasourceFields.map((field) => 
        bodyLine = bodyLine.replace("$(" + field.FieldLabel + ")",field.DefaultTestValue)
        )}      
      
      return bodyLine
    }

    const AddBodyLine = () => {
      props.IntegrationSettings.NotificationTemplate.Body.push("")
      SetRepainter(!mRepainter)
    }

    const AddBodyVariable = (aValue, aIndex) => {
      props.IntegrationSettings.NotificationTemplate.Body[aIndex] += aValue
      SetRepainter(!mRepainter)
    }

    const DeleteBodyLine = (aIndex) => {
      props.IntegrationSettings.NotificationTemplate.Body.splice(aIndex, 1)
      SetRepainter(!mRepainter)
    }

    const UpdateBody = (aNewValue, aIndex) => {
      props.IntegrationSettings.NotificationTemplate.Body[aIndex] = aNewValue
      SetRepainter(!mRepainter)
    }

    const UpdateHeader = (aNewValue) => {
      props.IntegrationSettings.NotificationTemplate.Header = aNewValue
      SetRepainter(!mRepainter)
    }
    
    ReplaceFieldTypes()
    
    const BrandingId = sessionStorage.getItem("BrandingId")

    return (
        <div>
            <IntegrationWizardStepHeader Header={Translate("Configure your call notification")}/>

            {props.StepWidth > 900 ?

            <table width="100%" cellPadding="0" cellSpacing="0">
            <tbody>
            <tr>
              <td width="533" valign="top">

                <div className="wizardstepheader">{Translate("Preview incoming call")}</div>
                <br/>

                <IntegrationWizardStepClientNotificationPreview IntegrationSettings={props.IntegrationSettings}/>

              </td>
              <td width="17"></td>
              <td width="*">

                <div className="wizardstepheader">{Translate("Configure incoming call")}</div>
                <br/>

                <div className="surface wizardsurface">
                  <table width="100%" cellPadding="20" cellSpacing="0">
                  <tbody>
                  <tr>
                      <td>
                        <div className="wizardfieldname">Header</div>
                        <span className="wizardfieldvalue"><input autoComplete="new-password" placeholder="" type="text" value={props.IntegrationSettings.NotificationTemplate.Header} onChange={evt => UpdateHeader(evt.target.value)} className="wizardinputfield"/></span>                 
                        <br clear="all"/><br/>
                        {props.IntegrationSettings.NotificationTemplate.Body.map((line,index) =>
                          <span key={index}> 
                            <div className="wizardfieldname">Body line {index+1}</div>
                            <div className="wizardfieldvalue" style={{ padding: '0px 0px 10px 0px'}}>
                              <input autoComplete="new-password" placeholder="" type="text" value={line} onChange={evt => UpdateBody(evt.target.value, index)} className="wizardinputfield"/> 

                              <Popup contentStyle={{ borderRadius: '16px', padding: '8px', background: 'var(--brand_wizardpopup_background)', color: 'var(--brand_wizardpopup_color)', borderWidth: '0px', boxShadow: '0px 6px 17px #BBBBBB' }} trigger={<Button variant="primary" className={BrandingId === "enreach" ? "brandbuttonprimary" : "brandbuttonkeeporiginalstyle"} id={"recognitionpopup" + index} style={{ marginTop: '-4px'}}>+ Add variable</Button>} arrow={false} position="bottom left" closeOnDocumentClick={true}>
                                <div className="wizardpopupheader">{Translate("Recognition fields")}</div>
                                {props.IntegrationSettings.DatasourceFields.map((field,findex) => 
                                  <div key={findex} onClick={() => { AddBodyVariable("$(" + field.FieldLabel + ")", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardpopupitem">{"$(" + field.FieldLabel + ")"}</div>
                                )}
                                <br/>
                                <div className="wizardpopupheader">{Translate("Call fields")}</div>
                                <div onClick={() => { AddBodyVariable("#(Caller number)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardpopupitem">{"#(Caller number)"}</div>
                                <div onClick={() => { AddBodyVariable("#(Caller name)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardpopupitem">{"#(Caller name)"}</div>
                                <div onClick={() => { AddBodyVariable("#(Device number)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardpopupitem">{"#(Device number)"}</div>
                                <div onClick={() => { AddBodyVariable("#(Device name)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardpopupitem">{"#(Device name)"}</div>
                                <div onClick={() => { AddBodyVariable("#(Ddi number)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardpopupitem">{"#(Ddi number)"}</div>
                                <div onClick={() => { AddBodyVariable("#(Ddi name)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardpopupitem">{"#(Ddi name)"}</div>
                                <div onClick={() => { AddBodyVariable("#(Application name)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardpopupitem">{"#(Application name)"}</div>
                              </Popup>

                                &nbsp; 

                                <Button variant="dark" className={BrandingId === "enreach" ? "brandbuttonprimary" : "brandbuttonkeeporiginalstyle"} onClick={() => DeleteBodyLine(index)} style={{ marginTop: '-4px'}}>Remove this line</Button>
                            </div>                 
                          </span>)}
                      </td>
                  </tr>
                  </tbody>
                  </table>
                </div>

                <br/>
                {props.IntegrationSettings.NotificationTemplate.Body.length < 4 ? <Button variant="dark" onClick={() => AddBodyLine()} className={BrandingId === "enreach" ? "brandbuttonprimary" : "brandbuttonkeeporiginalstyle"}>+ Add line</Button> : ""}

              </td>
            </tr>
            </tbody>
            </table> 
            
            :

            <table width="100%" cellPadding="0" cellSpacing="0">
            <tbody>
            <tr>
              <td width="*" valign="top">

                <div className="wizardstepheader">{Translate("Preview")}</div>

                <IntegrationWizardStepClientNotificationPreview IntegrationSettings={props.IntegrationSettings}/>

              </td>
            </tr>
            <tr height="12"/>
            <tr>
              <td>

                <div className="surface wizardsurface">
                  <table width="100%" cellPadding="20" cellSpacing="0">
                  <tbody>
                  <tr>
                      <td>
                        <div className="wizardfieldname">Header</div>
                        <span className="wizardfieldvalue"><input autoComplete="new-password" placeholder="" type="text" value={props.IntegrationSettings.NotificationTemplate.Header} onChange={evt => UpdateHeader(evt.target.value)} className="wizardinputfield"/></span>                 
                      
                        {props.IntegrationSettings.NotificationTemplate.Body.map((line,index) =>
                          <span key={index}> 
                            <br clear="all"/><br/>
                            <div className="wizardfieldname">Body line {index+1}</div>
                            <div className="wizardfieldvalue"><input autoComplete="new-password" placeholder="" type="text" value={line} onChange={evt => UpdateBody(evt.target.value, index)} className="wizardinputfield"/></div> 
                            <span className="wizardfieldvalue">

                            <Popup contentStyle={{ borderRadius: '16px', padding: '8px', background: 'var(--brand_wizardpopup_background)', color: 'var(--brand_wizardpopup_color)', borderWidth: '0px', boxShadow: '0px 6px 17px #BBBBBB' }} trigger={<Button variant="primary" className={BrandingId === "enreach" ? "brandbuttonprimary" : "brandbuttonkeeporiginalstyle"} id={"recognitionpopup" + index} style={{ marginTop: '-4px'}}>+ Add variable</Button>} arrow={false} position="bottom left" closeOnDocumentClick={true}>
                              <div className="wizardpopupheader">{Translate("Recognition fields")}</div>
                              {props.IntegrationSettings.DatasourceFields.map((field,findex) => 
                                <div key={findex} onClick={() => { AddBodyVariable("$(" + field.FieldLabel + ")", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardpopupitem">{"$(" + field.FieldLabel + ")"}</div>
                              )}
                              <br/>
                              <div className="wizardpopupheader">{Translate("Call fields")}</div>
                              <div onClick={() => { AddBodyVariable("#(Caller number)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardpopupitem">{"#(Caller number)"}</div>
                              <div onClick={() => { AddBodyVariable("#(Caller name)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardpopupitem">{"#(Caller name)"}</div>
                              <div onClick={() => { AddBodyVariable("#(Device number)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardpopupitem">{"#(Device number)"}</div>
                              <div onClick={() => { AddBodyVariable("#(Device name)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardpopupitem">{"#(Device name)"}</div>
                              <div onClick={() => { AddBodyVariable("#(Ddi number)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardpopupitem">{"#(Ddi number)"}</div>
                              <div onClick={() => { AddBodyVariable("#(Ddi name)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardpopupitem">{"#(Ddi name)"}</div>
                              <div onClick={() => { AddBodyVariable("#(Application name)", index); document.getElementById("recognitionpopup" + index).click()}} className="wizardpopupitem">{"#(Application name)"}</div>
                            </Popup>

                            &nbsp; 

                            <Button variant="dark" className={BrandingId === "enreach" ? "brandbuttonprimary" : "brandbuttonkeeporiginalstyle"} onClick={() => DeleteBodyLine(index)} style={{ marginTop: '-4px'}}>Remove this line</Button>
                          </span>                 
                        </span>
                        )}
                      </td>
                  </tr>
                  </tbody>
                  </table>
                </div>

                <br/>
                {props.IntegrationSettings.NotificationTemplate.Body.length < 4 ? <Button variant="dark" onClick={() => AddBodyLine()} className={BrandingId === "enreach" ? "brandbuttonprimary" : "brandbuttonkeeporiginalstyle"}>+ Add line</Button> : ""}

              </td>
            </tr>
            </tbody>
            </table> 
            }
        </div>
    )
}

IntegrationWizardStepClientNotification.propTypes = {
    IntegrationSettings: PropTypes.object,
    PredefinedHeader: PropTypes.string,
    PredefinedBody: PropTypes.array,
    StepWidth: PropTypes.number
}

export default IntegrationWizardStepClientNotification