import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import { Button } from "react-bootstrap";
import { OAuthApiStartPolling, OAuthApiStopPolling } from "../../ExternalApi/OAuthApi"
import { ConfigurationApiForward } from "../../ExternalApi/ConfigurationApi"
import IntegrationWizardDots from "../IntegrationWizard/IntegrationWizardDots"
import { GetDatasourceName } from "../Datasources/Datasources.jsx"
import { random } from "lodash";

const DatasourceBaseOauth = (props) => {
    
    const [mRepainter, SetRepainter] = useState(false); 
    const [mWaitingForState, SetWaitingForState] = useState("")
    const [mError, SetError] = useState("") 
    const [mOAuthWindow, SetOAuthWindow] = useState(null) 

    useEffect(() => {
        SetReady()
      }, []); 

    const UpdateDatasourceSetting = (aNewValue, aIdentifier) =>
    {
        SetRepainter(!mRepainter)
        props.IntegrationSettings.DatasourceSettings[aIdentifier] = aNewValue

        SetReady()
    }

    const SetReady = () => 
    {
        props.OnSetReady(props.IntegrationSettings.DatasourceSettings.RefreshToken)
    }

    async function GetTokens(aAuthorizationCode) 
    {
        const Url = props.TokenCodeReplace ? props.TokenUrl.replace("{code}",aAuthorizationCode) : props.TokenUrl + (props.TokenCodeInBody ? "" : aAuthorizationCode)
        const Body = props.TokenCodeReplace ? props.TokenBody.replace("{code}",aAuthorizationCode) : props.TokenBody + (props.TokenCodeInBody ? aAuthorizationCode : "")

        let Result = await ConfigurationApiForward(
            props.SecurityToken, 
            Url, 
            "POST", 
            Body, 
            "application/json", 
            props.TokenBodyType)
        if (Result != null)
        {
            UpdateDatasourceSetting(Result.data.access_token, "AccessToken")
            UpdateDatasourceSetting(Result.data.refresh_token, "RefreshToken")
        }
        else
        {
            SetError("Failed to retrieve tokens")
        }

        SetWaitingForState("")
    }

    const OAuthPollResult = (aResult, aValue, aOAuthWindow) =>
    {
        try 
        { 
            if (aOAuthWindow !== null)
            {
                aOAuthWindow.close() 
                aOAuthWindow = null
                SetOAuthWindow(null)
            }
        } 
        catch {}

        if (aResult)
        {
            console.log("OAuthPollResult success: " + JSON.stringify(aValue))
            if (aValue.Code && aValue.ResponseUrl)
            {
                GetTokens(aValue.Code)
            }
            else
            {
                SetError("Failed to authenticate. No authorizationcode found")  
            }
        }
        else
        {
            console.log("OAuthPollResult error: " + JSON.stringify(aValue))  
            SetError("Failed to authenticate")          
        }
    }

    const RandomState = () => 
    {
        let Result = "client2020WEB"

        var characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        for ( var i = 0; i < 27; i++ ) 
        {
            Result += characters.charAt(Math.floor(Math.random() * characters.length))
        }
        
        return Result
    }

    const StartOAuth = () =>
    {
        const State = RandomState()

        const OAuthUrl = props.AuthUrl + "&state=" + State
        const OAuthWindow = window.open(OAuthUrl, "_blank", 'popup=yes,titlebar=0,left=' + ((screen.availWidth - 750)/2)  + ',top=' + ((screen.availHeight - 750)/2)  + ',width=750,height=750')
        SetOAuthWindow(OAuthWindow)
        OAuthApiStartPolling(State, OAuthPollResult, OAuthWindow)

        SetWaitingForState(State)
    }

    const StopOAuth = () =>
    {
        try 
        { 
            if (mOAuthWindow !== null)
            {
                mOAuthWindow.close() 
                SetOAuthWindow(null)
            }
        } 
        catch {}

        OAuthApiStopPolling()

        SetWaitingForState("")
    }

    const BrandingId = sessionStorage.getItem("BrandingId")

    const Body = props.IntegrationSettings.DatasourceSettings.RefreshToken ?
        //Authorization OK
        <div className="scriptitembody">
            {Translate("$Application connection token received succesfully").replace("$Application",GetDatasourceName(props.IntegrationSettings.DatasourceType))}
        </div> :
        (mError ?
            //Authorization failed. Show error and connect button
            <div className="scriptitembody">
                <Button variant="primary" className={BrandingId === "enreach" ? "brandbuttonprimary" : "brandbuttonkeeporiginalstyle"} onClick={() => StartOAuth()} type="submit">{Translate("Connect")}</Button><br/>
                <div>{mError}</div>
            </div> :
            (mWaitingForState ?
                //Waiting for authorization. Show dot counter and cancel button
                <div className="scriptitembody">
                    <Button variant="danger" className={BrandingId === "enreach" ? "brandbuttonprimary" : "brandbuttonkeeporiginalstyle"} onClick={() => StopOAuth()} type="submit">{Translate("Cancel")}</Button><br/><br/>
                    <div>{Translate("Waiting for OAUTH verification")}<IntegrationWizardDots/></div>
                </div> :
                //Not authenticating. Show connect button
                <div className="scriptitembody">
                    <Button variant="primary" className={BrandingId === "enreach" ? "brandbuttonprimary" : "brandbuttonkeeporiginalstyle"} onClick={() => StartOAuth()} type="submit">{Translate("Connect")}</Button>
                </div>
            )
        )

    return (
        <span>{Body}</span>
    )
}

DatasourceBaseOauth.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    AuthUrl: PropTypes.string, //the url of the oauth request in your browser
    TokenUrl: PropTypes.string, //the url of the gettoken POST with the authorizationcode
    TokenBody: PropTypes.string, //the body of the gettoken POST request. the authorizationcode will be added to this
    TokenBodyType: PropTypes.string, //the body type of the gettoken POST request body. usually "application/x-www-form-urlencoded"
    TokenCodeInBody: PropTypes.bool, //whether the authorization code should be added to the token request in the body or in the url
    TokenCodeReplace: PropTypes.bool = false, //Whether the code should replace '{code}' in body and url
    OnSetReady: PropTypes.func
}   

export default DatasourceBaseOauth